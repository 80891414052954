import React from 'react';
import './ErrorPage.css';

export const PaymentSuccessful: React.FC = () => {
  return (
    <div className='error-page'>
      <img src='/images/payment-unsuccessful.svg' />
      <h1>Successful Payment ✔️</h1>
      <div className='info-text'>Your payment has been successfully received</div>
      <button className="button-primary" onClick={() => window.location.reload()}>Return to Payment Form</button>
    </div>
  );
};
